module.exports = {
  en: {
    path: "en",
    locale: "English"
  },
  ja: {
    path: "ja",
    locale: "Japanese"
  },
  es: {
    path: "es",
    locale: "Spanish"
  },
  ru: {
    path: "ru",
    locale: "Russian"
  },
  zh: {
    path: "zh",
    locale: "Chinese",
    default: true,
  },
  
}
