import React, { Component } from "react"
import { FormattedMessage } from "react-intl"
import { navigate, withPrefix } from "gatsby-link"
import LogoLight from "./logo-light"
import LogoDark from "./logo-dark"
import LocalizedLink from "./localized-link"

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import "./navbar.css"

export default class ReactNavbar extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
      scrolling: false,
      dropdownOpen: false,
      lang: "en",
      langs: {
        en: "English",
        zh: "中文",
        ja: "日本語",
        es: "Español",
        ru: "русский",
      },
    }
    this.mainMenuScroll = this.mainMenuScroll.bind(this)
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  setLang() {
    let lang = "en"
    if (typeof window !== "undefined") {
      let pathLang = window.location.pathname.split("/")[1]
      if (pathLang) {
        lang = pathLang || "en"
      }
    }
    this.setState({
      lang: lang,
    })
  }
  mainMenuScroll() {
    if (window.scrollY >= 40) {
      if (this.state.scrolling !== true) {
        this.setState({
          scrolling: true,
        })
      }
    } else if (this.state.scrolling !== false) {
      this.setState({
        scrolling: false,
      })
    }
  }
  toggleLang = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }
  switchLang = e => {
    let lang = e
    if (typeof window !== "undefined") {
      const newUrl = withPrefix(
        window.location.pathname.replace(/\/(zh|en|ja|es|ru)/, lang)
      )
      window.localStorage.setItem("language", lang)
      navigate(newUrl)
    }
  }

  componentDidMount() {
    console.log("lang:" + this.state.lang)
    window.addEventListener("scroll", this.mainMenuScroll, false)
    this.setLang()
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.mainMenuScroll, false)
  }

  render() {
    return (
      <Navbar
        color={this.state.scrolling ? "white" : "transparent"}
        fixed="top"
        expand="md"
      >
        <div className="navbar-content">
          <NavbarBrand>
            <LocalizedLink to="/">
              {this.state.scrolling ? <LogoDark /> : <LogoLight />}
            </LocalizedLink>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse
            isOpen={this.state.isOpen}
            navbar
            style={{ minWidth: "560px" }}
          >
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink
                  tag={() => (
                    <LocalizedLink
                      className="nav-link"
                      activeClassName="active"
                      to="/"
                    >
                      <FormattedMessage id="org.massnet.components.header.home" />
                    </LocalizedLink>
                  )}
                />
              </NavItem>
              <NavItem>
                <NavLink
                  tag={() => (
                    <LocalizedLink
                      className="nav-link"
                      activeClassName="active"
                      to="/research"
                    >
                      <FormattedMessage id="org.massnet.components.header.research" />
                    </LocalizedLink>
                  )}
                />
              </NavItem>
              <NavItem>
                <NavLink
                  tag={() => (
                    <LocalizedLink
                      className="nav-link"
                      activeClassName="active"
                      to="/develop"
                    >
                      <FormattedMessage id="org.massnet.components.header.history" />
                    </LocalizedLink>
                  )}
                />
              </NavItem>
              <NavItem>
                <NavLink
                  tag={() => (
                    <LocalizedLink
                      className="nav-link"
                      activeClassName="active"
                      to="/download"
                    >
                      <FormattedMessage id="org.massnet.components.header.download" />
                    </LocalizedLink>
                  )}
                />
              </NavItem>
              <NavItem>
                <NavLink
                  tag={() => (
                    <LocalizedLink
                      className="nav-link"
                      activeClassName="active"
                      to="/about"
                    >
                      <FormattedMessage id="org.massnet.components.header.about" />
                    </LocalizedLink>
                  )}
                />
              </NavItem>
              <NavItem>
                <NavLink
                  tag={() => (
                    <LocalizedLink
                      className="nav-link"
                      activeClassName="active"
                      to="/blog"
                    >
                      <FormattedMessage id="org.massnet.components.header.blog" />
                    </LocalizedLink>
                  )}
                />
              </NavItem>
              <UncontrolledDropdown setActiveFromChild className="lang-item">
                <DropdownToggle tag="a" className="nav-link" tabIndex="0" caret>
                  {this.state.langs[this.state.lang]}
                </DropdownToggle>
                <DropdownMenu>
                  {Object.entries(this.state.langs).map(([key, value]) => {
                    return (
                      <DropdownItem
                        tag="a"
                        key={key}
                        className={
                          "lang-link " +
                          (this.state.lang == { key }.key ? "active" : "")
                        }
                        onClick={e => {
                          e.preventDefault()
                          this.switchLang({ key }.key)
                        }}
                      >
                        {value}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
          <a
            className="test-net-label"
            href="https://wallet.massnet.org"
          >
            <FormattedMessage id="org.massnet.components.header.test_net" />
          </a>
        </div>
      </Navbar>
    )
  }
}
