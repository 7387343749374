import React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DevelopmentDetails from "../components/development-details"
import HeaderDevelop from "../components/header-develop"

const DevelopPage = ({ pageContext: { locale, keywords } }) => (
  <Layout locale={locale}>
    <HeaderDevelop />
    <SEO
      title="Development"
      keywords={[
        ...keywords,
        `MASS Blockchain Consensus Engine`,
        `MASS 发展历程`
      ]}
      lang={locale}
    />
    <DevelopmentDetails />
  </Layout>
)

DevelopPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    keywords: PropTypes.array.isRequired,
  }).isRequired,
}

export default DevelopPage
